// #region types
import type { CategoryRootDataModel } from 'types/oneflare.com.au/categoryRoot';
// #endregion types

// #region hooks/higher-order components
import { useIsMounted } from 'shared/utils/hooks';
import { useEffect, useState } from 'react';
// #endregion hooks/higher-order components

// #region config
import { publicRuntimeConfig } from 'lib/utils/Environment';
// #endregion config

// eslint-disable-next-line prefer-destructuring
const CACHE_KEY_PREFIX = process.env.CACHE_KEY_PREFIX;

export const usePageTracking = (categoryRootData: CategoryRootDataModel) => {
  const [pageTracking, setPageTrackingConfig] = useState({
    environment: '',
    deploymentVersion: '',
    locationId: '',
    categoryId: '',
    categoryName: '',
    zoneId: '',
    region_id: '',
    state_id: ''
  });
  const didMount = useIsMounted();

  useEffect(() => {
    if (didMount) {
      setPageTrackingConfig((pageTracking) => ({
        ...pageTracking,
        ...{
          environment: publicRuntimeConfig.ENVIRONMENT,
          deployment_version: CACHE_KEY_PREFIX,
          category_id: Number(categoryRootData?.category?.id),
          category_name: categoryRootData?.category?.name,
          form_type: 'job_request',
          form_step: '01',
          form_version: '3'
        }
      }));
    }
  }, [categoryRootData, didMount]);

  return pageTracking;
};
